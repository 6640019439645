import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './mobileView.module.css';
var MobileView = function () {
    var _a, _b;
    var i18n = useTranslation().i18n;
    var handleLang = function (event) {
        var target = event.target;
        var btn = target.closest('button');
        if (!btn)
            return;
        i18n.changeLanguage(btn.value);
    };
    return (_jsxs("div", { className: styles.mobileView, onClick: handleLang, children: [_jsx("button", { className: cn(styles.lang, (_a = {}, _a[styles.lang_active] = i18n.language === 'ru', _a)), value: "ru", children: "ru" }), _jsx("button", { className: cn(styles.lang, (_b = {}, _b[styles.lang_active] = i18n.language === 'en', _b)), value: "en", children: "en" })] }));
};
export default MobileView;
