import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './copyright.module.css';
var Copyright = function () {
    var t = useTranslation().t;
    var content = t('copyright__content', { returnObjects: true });
    return (_jsx("div", { className: styles.copyright, children: content.map(function (item, i) {
            return i === content.length - 1 ? (_jsx("a", { className: styles.copyright__elem, target: "_blank", href: "https://krispy-studio.design/", children: item }, "copyright_".concat(i))) : (_jsx("div", { className: styles.copyright__elem, children: item }, "copyright_".concat(i)));
        }) }));
};
export default Copyright;
