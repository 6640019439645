import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import Portal from '../Portal';
import { Alert } from 'antd';
import styles from './note.module.css';
import { useTranslation } from 'react-i18next';
var Note = function (_a) {
    var setShowNote = _a.setShowNote, isSuccess = _a.isSuccess;
    var t = useTranslation().t;
    useEffect(function () {
        var timerId = setTimeout(function () { return setShowNote(false); }, 4000);
        return function () {
            clearTimeout(timerId);
        };
    }, []);
    return (_jsx(Portal, { children: _jsx(Alert, { className: styles.note, message: t("note__title_".concat(isSuccess ? 'success' : 'error')), description: t("note__text_".concat(isSuccess ? 'success' : 'error')), type: isSuccess ? 'success' : 'error', showIcon: true }) }));
};
export default Note;
