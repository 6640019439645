var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState, useEffect, useRef } from 'react';
import validation from '../../validation';
import cn from 'classnames';
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/bootstrap.css';
import styles from './field.module.css';
var hasValue = function (value, focused) {
    return value !== '' || focused;
};
var Field = function (props) {
    var _a, _b, _c;
    var name = props.name, label = props.label, type = props.type, autocomplete = props.autocomplete, value = props.value, setValue = props.setValue, className = props.className, errors = props.errors, setErrors = props.setErrors;
    var _d = useState(false), focused = _d[0], setFocused = _d[1];
    var _e = useState(false), touched = _e[0], setTouched = _e[1];
    var ref = useRef(null);
    var _f = useTranslation(), t = _f.t, i18n = _f.i18n;
    useEffect(function () {
        var _a;
        if (touched && !focused) {
            validation((_a = {}, _a[name] = value[name], _a), setErrors);
        }
    }, [touched, focused]);
    useEffect(function () {
        if (type === 'tel') {
            var defaultValue_1 = i18n.language === 'ru' ? '+7' : '+66';
            setValue(function (prev) {
                var _a;
                return (__assign(__assign({}, prev), (_a = {}, _a[name] = defaultValue_1, _a)));
            });
        }
    }, [i18n.language]);
    var handleFocus = useCallback(function () {
        setFocused(true);
        setTouched(true);
    }, []);
    var handleBlurComp = useCallback(function () {
        setFocused(false);
    }, []);
    var handleChange = useCallback(function (e) {
        setValue(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[name] = e.target.value, _a)));
        });
        setErrors(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[name] = '', _a)));
        });
    }, []);
    var handleChangePhone = useCallback(function (value, data) {
        var countryCode = !!data && typeof data === 'object' && 'dialCode' in data && typeof data.dialCode === 'string' && data.dialCode;
        setValue(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[name] = "".concat(countryCode, "_").concat(value.slice(countryCode.length)), _a)));
        });
        setErrors(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[name] = '', _a)));
        });
    }, []);
    var hasVal = hasValue(value[name], focused);
    var labelCls = cn(styles.label, (_a = {},
        _a[styles.label_phone] = type === 'tel',
        _a[styles.label_active] = hasVal,
        _a[styles.label_hide] = value[name] && !focused,
        _a[styles.label_error] = errors[name],
        _a));
    var inputCls = cn(styles.input, (_b = {},
        _b[styles.input_phone] = type === 'tel',
        _b[styles.input_focus] = hasVal,
        _b[styles.input_error] = errors[name],
        _b));
    var cls = cn(styles.field, className);
    return (_jsxs("div", { className: cls, onFocus: handleFocus, onBlur: handleBlurComp, ref: ref, children: [type === 'tel' ? (_jsx(PhoneInput, { countryCodeEditable: false, masks: { ru: '(...) ... ....', th: '.. ... ....' }, inputProps: { id: name, name: name }, country: i18n.language === 'ru' ? 'ru' : 'th', placeholder: "", containerClass: styles.phoneContainer, inputClass: inputCls, value: value[name], onChange: function (value, data) { return handleChangePhone(value, data); } })) : (_jsx("input", { id: name, className: inputCls, type: type, autoComplete: autocomplete, onChange: handleChange, value: value[name] })), _jsx("label", { className: labelCls, htmlFor: name, children: label }), _jsx("div", { className: cn(styles.errorText, (_c = {}, _c[styles.errorText_active] = errors[name], _c)), children: errors[name] && _jsx("div", { children: t(errors[name]) }) })] }));
};
export default Field;
