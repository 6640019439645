import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Langs from '../Langs';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './nav.module.css';
var Nav = function (props) {
    var _a = props.className, className = _a === void 0 ? '' : _a, handleActiveMenu = props.handleActiveMenu;
    var t = useTranslation().t;
    var handleClick = function (event) {
        var target = event.target;
        if (target.dataset.tag === 'nav_elem') {
            target.dataset.to in props && props[target.dataset.to].current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
            handleActiveMenu && handleActiveMenu();
        }
    };
    var navCls = cn(styles.nav, className);
    return (_jsxs("nav", { className: navCls, onClick: handleClick, children: [_jsx("a", { className: styles.nav__elem, "data-tag": "nav_elem", "data-to": "about", children: t('nav__about') }), _jsx("a", { className: styles.nav__elem, "data-tag": "nav_elem", "data-to": "roomsLayout", children: t('nav__layouts') }), _jsx("a", { className: styles.nav__elem, "data-tag": "nav_elem", "data-to": "questions", children: "FAQ" }), _jsx("a", { className: styles.nav__elem, "data-tag": "nav_elem", "data-to": "contacts", children: t('nav__contacts') }), props.isHeader && _jsx(Langs, {})] }));
};
export default Nav;
