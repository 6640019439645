var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useLayoutEffect, useRef, useState } from 'react';
import Nav from '../../ui-kit/Nav';
import { useIsMobile } from '../../utils/useIsMobile';
import MobileMenu from './MobileMenu';
import styles from './header.module.css';
var Header = function (props) {
    var isMobile = useIsMobile();
    var _a = useState(false), isActiveMobileMenu = _a[0], setIsActiveMobileMenu = _a[1];
    var ref = useRef();
    useLayoutEffect(function () {
        var zoom = document.documentElement.offsetWidth / 1920;
        if (zoom > 1) {
            ref.current.style.zoom = zoom.toString();
        }
    }, []);
    var toTop = function () {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    var handleActiveMenu = function () {
        setIsActiveMobileMenu(!isActiveMobileMenu);
    };
    return (_jsxs("header", { className: styles.header, ref: ref, children: [_jsx("button", { className: styles.logo, onClick: toTop }), !isMobile && _jsx(Nav, __assign({}, props, { isHeader: true })), isMobile && _jsx("button", { className: styles.mobile_menu__btn, onClick: handleActiveMenu }), isMobile && isActiveMobileMenu && _jsx(MobileMenu, __assign({}, props, { handleActiveMenu: handleActiveMenu, isActive: true }))] }));
};
export default Header;
