var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Nav from '../../ui-kit/Nav';
import Langs from '../../ui-kit/Langs';
import Social from '../../ui-kit/Social';
import Copyright from '../../ui-kit/Copyright';
import cn from 'classnames';
import styles from './mobileMenu.module.css';
var MobileMenu = function (props) {
    var _a;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.overlay, onClick: props.handleActiveMenu }), _jsxs("div", { className: cn(styles.mobileMenu, (_a = {}, _a[styles.mobileMenu_active] = props.isActive, _a)), "data-tag": "mobileMenu", children: [_jsx("button", { className: styles.btn_close, onClick: props.handleActiveMenu }), _jsx("div", { className: styles.logo }), _jsx(Nav, __assign({}, props, { className: styles.nav })), _jsx(Langs, {}), _jsxs("div", { className: styles.bottom_group, children: [_jsx(Social, {}), _jsx(Copyright, {})] })] })] }));
};
export default MobileMenu;
