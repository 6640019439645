import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ImageWrapper from '../../ui-kit/ImageWrapper';
import Link from '../../ui-kit/Link';
import { HOUSE_DESCRIPTION } from '../../consts';
import { getPresentationLink } from '../../helpers';
import { useIsMobile } from '../../utils/useIsMobile';
import gsap from 'gsap';
import styles from './information.module.css';
var Information = function () {
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    var elemsRef = useRef([]);
    var isMobile = useIsMobile();
    useEffect(function () {
        var mobileCallback = function (entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    var target = entry.target;
                    gsap.to(target, {
                        opacity: 1,
                        x: 0,
                        duration: 1,
                        ease: 'power2.out',
                    });
                    observer.unobserve(target);
                }
            });
        };
        var desktopCallback = function (_a) {
            var entry = _a[0];
            if (entry.isIntersecting) {
                gsap.fromTo(elemsRef.current, { opacity: 0, x: -50 }, {
                    opacity: 1,
                    x: 0,
                    duration: 1,
                    stagger: 0.3,
                    ease: 'power2.out',
                });
                observer.disconnect();
            }
        };
        var observer = new IntersectionObserver(isMobile ? mobileCallback : desktopCallback, { threshold: 0.5 });
        elemsRef.current.forEach(function (el, index) {
            if (isMobile || index === 0)
                observer.observe(el);
        });
        return function () { return observer.disconnect(); };
    }, [isMobile]);
    var descriptions = t('information__data', { returnObjects: true });
    var link = getPresentationLink(i18n.language);
    return (_jsxs("div", { className: styles.information, children: [_jsx("div", { className: styles.title, children: "Enigma Residence" }), _jsx("div", { className: styles.text, children: t('information__text') }), _jsx(Link, { href: link, className: styles.link, children: t('information__link') }), _jsx("div", { className: styles.content, children: HOUSE_DESCRIPTION.map(function (_a, i) {
                    var title = _a.title, image = _a.image;
                    return (_jsxs("div", { style: { opacity: 0, transform: 'translate(-40px, 0)' }, className: styles.content__block, ref: function (el) { return (elemsRef.current[i] = el); }, children: [_jsx(ImageWrapper, { className: styles.image_wrapper, children: _jsx("div", { style: { backgroundImage: "url(../../../static/images/components/".concat(image, ".png)") } }) }), _jsx("div", { className: styles.text_wrapper, children: _jsxs("div", { children: [_jsx("div", { className: styles.content__block__title, children: title }), _jsx("div", { className: styles.description, children: descriptions[i] })] }) })] }, "information_".concat(i)));
                }) })] }));
};
export default Information;
