import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { getImageLink } from '../../helpers';
import { SOCIALS } from '../../consts';
import styles from './social.module.css';
var Social = function () {
    return (_jsx("div", { className: styles.social, children: SOCIALS.map(function (_a, i) {
            var name = _a.name, link = _a.link;
            var image_link = getImageLink(name, '.svg', 'ui-kit');
            return _jsx("a", { href: link, target: "_blank", className: styles.social__elem, style: { maskImage: "url(".concat(image_link, ")") } }, "social_".concat(i));
        }) }));
};
export default Social;
