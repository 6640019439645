import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import cn from 'classnames';
import { useIsMobile } from '../../utils/useIsMobile';
import MobileView from './MobileView';
import { useTranslation } from 'react-i18next';
import styles from './langs.module.css';
var Langs = function () {
    var ref = useRef(null);
    var isMobile = useIsMobile();
    var i18n = useTranslation().i18n;
    var handleVisibleOn = function () {
        ref.current.classList.add(styles.block_active);
    };
    var handleVisibleOff = function () {
        ref.current.classList.remove(styles.block_active);
    };
    var handleLanguage = function () {
        i18n.changeLanguage(i18n.language === 'en' ? 'ru' : 'en');
        handleVisibleOff();
    };
    if (isMobile) {
        return _jsx(MobileView, {});
    }
    return (_jsxs("div", { className: styles.langs, onMouseEnter: handleVisibleOn, onMouseLeave: handleVisibleOff, children: [_jsx("div", { className: cn(styles.lang, styles.active_lang), children: i18n.language }), _jsx("div", { className: styles.block, ref: ref, children: _jsx("button", { className: styles.lang, onClick: handleLanguage, children: i18n.language === 'en' ? 'ru' : 'en' }) })] }));
};
export default Langs;
