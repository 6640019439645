import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import styles from './link.module.css';
var Link = function (_a) {
    var href = _a.href, children = _a.children, _b = _a.className, className = _b === void 0 ? '' : _b;
    var linkClass = cn(styles.link, className);
    return (_jsx("a", { className: linkClass, href: href, target: "_blank", children: children }));
};
export default Link;
