import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { MAPS_KEY } from '../../../key';
// import { getImageLink } from '../../helpers';
import Button from '../Button';
import styles from './map.module.css';
var center = {
    lat: 7.812960873536211,
    lng: 98.33940945240678,
};
var stylesMap = [
    {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi.business',
        elementType: 'all',
        stylers: [
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'poi.business',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.school',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi.sports_complex',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit.station.bus',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
            {
                saturation: '21',
            },
            {
                weight: '4.05',
            },
        ],
    },
];
var Map = function () {
    var ref = useRef(null);
    var isLoaded = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: MAPS_KEY,
    }).isLoaded;
    var t = useTranslation().t;
    useEffect(function () { }, [isLoaded]);
    var openLink = useCallback(function () { return window.open('https://maps.app.goo.gl/cwYubmZm3TvmSm9k8', '_blank'); }, []);
    // const link = getImageLink('marker', '.svg', 'ui-kit');
    return isLoaded ? (_jsxs(GoogleMap, { id: "googleMaps", mapContainerClassName: styles.map_wrapper, center: center, zoom: 14.8, options: {
            styles: stylesMap,
            disableDefaultUI: true,
            keyboardShortcuts: false,
        }, ref: ref, children: [_jsx(Button, { className: styles.btn, onClick: openLink, children: t('map__btn') }), _jsx(_Fragment, { children: _jsx(Marker, { position: center }) })] })) : (_jsx(_Fragment, {}));
};
export default React.memo(Map);
