import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import '@splidejs/react-splide/css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { getImageLink } from '../../helpers';
import { CONVENIENCE_IMAGES } from '../../consts';
import { useTranslation } from 'react-i18next';
import styles from './conveniences.module.css';
var Conveniences = function () {
    var t = useTranslation().t;
    var splideRef = useRef(null);
    var _a = useState(false), splideInited = _a[0], setSplideInited = _a[1];
    var _b = useState(0), activeIndex = _b[0], setActiveIndex = _b[1];
    var slidesRef = useRef([]);
    useEffect(function () {
        var _a;
        var splideInstance = (_a = splideRef.current) === null || _a === void 0 ? void 0 : _a.splide;
        if (splideInstance && !splideInited) {
            splideInstance.on('move', function (newIndex) {
                setActiveIndex(newIndex);
            });
            setSplideInited(true);
        }
    }, []);
    useEffect(function () {
        slidesRef.current[activeIndex].classList.add(styles.activeSlide);
        return function () { return slidesRef.current[activeIndex].classList.remove(styles.activeSlide); };
    }, [activeIndex]);
    var descriptions = t('convenience__descriptions', {
        returnObjects: true,
    });
    var options = useMemo(function () {
        return {
            arrows: false,
            pagination: false,
            direction: 'ltr',
            type: 'loop',
            autoWidth: true,
            height: 460,
            gap: '10em',
            focus: 'center',
            speed: 1000,
            perMove: 1,
            autoplay: true,
            interval: 3000,
            pauseOnHover: false,
            breakpoints: {
                1152: {
                    gap: '8em',
                    height: 360,
                },
                767: {
                    gap: '3em',
                    height: 380,
                },
            },
        };
    }, [document.documentElement.offsetWidth]);
    return (_jsxs("div", { className: styles.conveniences, children: [_jsx("div", { className: styles.title, children: t('convenience__title') }), _jsx("div", { className: styles.splide_wrapper, children: _jsx(Splide, { options: options, className: styles.splide, ref: splideRef, children: CONVENIENCE_IMAGES.map(function (item, i) {
                        return (_jsx(SplideSlide, { children: _jsx("div", { className: styles.slide_wrapper, children: _jsx("div", { style: { backgroundImage: "url(".concat(getImageLink(item, '.png', 'conceniences'), ")") }, className: styles.slide, ref: function (el) { return (slidesRef.current[i] = el); } }) }) }, "conveniences_image_".concat(i)));
                    }) }) }), _jsx("div", { className: styles.text_content, children: descriptions.map(function (item, i) {
                    return (_jsxs(React.Fragment, { children: [_jsx("span", { className: styles.text, children: item }), i !== descriptions.length - 1 && _jsx("span", { className: styles.text, children: "|" })] }, "conveniences_text_".concat(i)));
                }) })] }));
};
export default Conveniences;
