export var ROOM_LAYOUTS = [
    {
        layoutId: 0,
        title: 'studio',
        total: 35.00,
        images: ['photo_1', 'photo_2', 'photo_3', 'photo_4', 'photo_5', 'photo_6', 'photo_7', 'photo_8'],
        sizes: [
            {
                name: 'living-room',
                size: 11.09
            },
            {
                name: 'kitchen',
                size: 6.09
            },
            {
                name: 'bedroom',
                size: 8.25
            },
            {
                name: 'bathroom',
                size: 5.51
            },
            {
                name: 'terrace',
                size: 4.06
            },
            {
                name: 'total',
                size: 35.00
            },
        ]
    },
    {
        layoutId: 1,
        title: '1 br a',
        total: 36.75,
        images: ['photo_1', 'photo_2', 'photo_3', 'photo_4', 'photo_5', 'photo_6', 'photo_7', 'photo_8', 'photo_9', 'photo_10', 'photo_11'],
        sizes: [
            {
                name: 'living-room',
                size: 6.86
            },
            {
                name: 'kitchen',
                size: 7.35
            },
            {
                name: 'bedroom',
                size: 14.54
            },
            {
                name: 'bathroom',
                size: 5.06
            },
            {
                name: 'terrace',
                size: 2.94
            },
            {
                name: 'total',
                size: 36.75
            },
        ]
    },
    {
        layoutId: 1,
        title: '1 br c',
        total: 45.50,
        images: ['photo_1', 'photo_2', 'photo_3', 'photo_4', 'photo_5', 'photo_6', 'photo_7', 'photo_8', 'photo_9', 'photo_10', 'photo_11'],
        sizes: [
            {
                name: 'living-room',
                size: 11.78
            },
            {
                name: 'kitchen',
                size: 8.39
            },
            {
                name: 'bedroom',
                size: 14.56
            },
            {
                name: 'bathroom',
                size: 5.61
            },
            {
                name: 'terrace',
                size: 5.16
            },
            {
                name: 'total',
                size: 45.50
            },
        ]
    },
    {
        layoutId: 1,
        title: '1 br d',
        total: 54.60,
        images: ['photo_1', 'photo_2', 'photo_3', 'photo_4', 'photo_5', 'photo_6', 'photo_7'],
        sizes: [
            {
                name: 'living-room',
                size: 11.58
            },
            {
                name: 'kitchen',
                size: 13.94
            },
            {
                name: 'bedroom',
                size: 15.08
            },
            {
                name: 'bathroom',
                size: 5.86
            },
            {
                name: 'terrace',
                size: 7.14
            },
            {
                name: 'total',
                size: 54.60
            },
        ]
    },
    {
        layoutId: 1,
        title: '1 br e',
        total: 58.80,
        images: ['photo_1', 'photo_2', 'photo_3', 'photo_4', 'photo_5', 'photo_6', 'photo_7', 'photo_8', 'photo_9', 'photo_10'],
        sizes: [
            {
                name: 'living-room',
                size: 13.91
            },
            {
                name: 'kitchen',
                size: 15.80
            },
            {
                name: 'bedroom',
                size: 18.30
            },
            {
                name: 'bathroom',
                size: 5.53
            },
            {
                name: 'terrace',
                size: 5.26
            },
            {
                name: 'total',
                size: 58.80
            },
        ]
    },
    {
        layoutId: 1,
        title: '1 br g',
        total: 71.75,
        images: ['photo_1', 'photo_2', 'photo_3', 'photo_4', 'photo_5', 'photo_6', 'photo_7', 'photo_8', 'photo_9', 'photo_10', 'photo_11'],
        sizes: [
            {
                name: 'living-room',
                size: 17.94
            },
            {
                name: 'kitchen',
                size: 17.83
            },
            {
                name: 'bedroom',
                size: 13.97
            },
            {
                name: 'dressing-room',
                size: 7.95
            },
            {
                name: 'bathroom',
                size: 6.38
            },
            {
                name: 'terrace',
                size: 7.68
            },
            {
                name: 'total',
                size: 71.75
            },
        ]
    },
    {
        layoutId: 2,
        title: '2 br b',
        total: 73.50,
        images: ['photo_1', 'photo_2', 'photo_3', 'photo_4', 'photo_5', 'photo_6', 'photo_7', 'photo_8', 'photo_9', 'photo_10', 'photo_11', 'photo_12'],
        sizes: [
            {
                name: 'living-room',
                size: 16.17
            },
            {
                name: 'kitchen',
                size: 11.03
            },
            {
                name: 'bedroom1',
                size: 11.40
            },
            {
                name: 'bathroom1',
                size: 8.50
            },
            {
                name: 'bedroom2',
                size: 16.35
            },
            {
                name: 'bathroom2',
                size: 4.65
            },
            {
                name: 'terrace',
                size: 5.40
            },
            {
                name: 'total',
                size: 73.50
            },
        ]
    },
    {
        layoutId: 2,
        title: '2 br c',
        total: 73.47,
        images: ['photo_1', 'photo_2', 'photo_3', 'photo_4', 'photo_5', 'photo_6', 'photo_7', 'photo_8', 'photo_9', 'photo_10', 'photo_11', 'photo_12'],
        sizes: [
            {
                name: 'living-room',
                size: 11.73
            },
            {
                name: 'kitchen',
                size: 17.00
            },
            {
                name: 'bedroom1',
                size: 10.20
            },
            {
                name: 'bathroom1',
                size: 5.10
            },
            {
                name: 'bedroom2',
                size: 15.34
            },
            {
                name: 'bathroom2',
                size: 5.10
            },
            {
                name: 'terrace',
                size: 9.00
            },
            {
                name: 'total',
                size: 73.47
            },
        ]
    },
    {
        layoutId: 2,
        title: '2 br d',
        total: 76.89,
        images: ['photo_1', 'photo_2', 'photo_3', 'photo_4', 'photo_5', 'photo_6', 'photo_7', 'photo_8', 'photo_9'],
        sizes: [
            {
                name: 'living-room',
                size: 14.88
            },
            {
                name: 'kitchen',
                size: 19.50
            },
            {
                name: 'bedroom1',
                size: 11.85
            },
            {
                name: 'bathroom1',
                size: 5.20
            },
            {
                name: 'bedroom2',
                size: 13.33
            },
            {
                name: 'bathroom2',
                size: 5.36
            },
            {
                name: 'terrace',
                size: 6.77
            },
            {
                name: 'total',
                size: 76.89
            },
        ]
    },
    {
        layoutId: 2,
        title: '2 br e',
        total: 96.67,
        images: ['photo_1', 'photo_2', 'photo_3', 'photo_4', 'photo_5', 'photo_6', 'photo_7', 'photo_8', 'photo_9', 'photo_10', 'photo_11', 'photo_12', 'photo_13'],
        sizes: [
            {
                name: 'living-room',
                size: 13.14
            },
            {
                name: 'kitchen',
                size: 20.55
            },
            {
                name: 'bedroom1',
                size: 18.07
            },
            {
                name: 'bathroom1',
                size: 6.10
            },
            {
                name: 'bedroom2',
                size: 14.46
            },
            {
                name: 'bathroom2',
                size: 5.95
            },
            {
                name: 'dressing-room',
                size: 3.82
            },
            {
                name: 'terrace',
                size: 6.77
            },
            {
                name: 'total',
                size: 96.67
            },
        ]
    },
    {
        layoutId: 2,
        title: '2 br f',
        total: 63.74,
        images: ['photo_1', 'photo_2', 'photo_3', 'photo_4', 'photo_5', 'photo_6', 'photo_7', 'photo_8', 'photo_9', 'photo_10'],
        sizes: [
            {
                name: 'living-room',
                size: 12.54
            },
            {
                name: 'kitchen',
                size: 15.76
            },
            {
                name: 'bedroom1',
                size: 12.33
            },
            {
                name: 'bathroom1',
                size: 4.70
            },
            {
                name: 'bedroom2',
                size: 10.55
            },
            {
                name: 'bathroom2',
                size: 5.36
            },
            {
                name: 'terrace',
                size: 2.10
            },
            {
                name: 'total',
                size: 63.74
            },
        ]
    },
    {
        layoutId: 2,
        title: '2 br g',
        total: 125.00,
        images: ['photo_1', 'photo_2', 'photo_3', 'photo_4', 'photo_5', 'photo_6', 'photo_7', 'photo_8', 'photo_9', 'photo_10', 'photo_11', 'photo_12'],
        sizes: [
            {
                name: 'living-room',
                size: 33.86
            },
            {
                name: 'kitchen',
                size: 23.37
            },
            {
                name: 'bedroom1',
                size: 11.21
            },
            {
                name: 'bathroom1',
                size: 5.88
            },
            {
                name: 'bedroom2',
                size: 12.59
            },
            {
                name: 'bathroom2',
                size: 9.81
            },
            {
                name: 'dressing-room',
                size: 9.18
            },
            {
                name: 'terrace',
                size: 19.10
            },
            {
                name: 'total',
                size: 125.00
            },
        ]
    }
];
export var HOUSE_DESCRIPTION = [
    { image: 'buildings', title: '3' },
    { image: 'layouts', title: '10+' },
    { image: 'rooms', title: '164' }
];
export var CONVENIENCE_IMAGES = [
    'gym',
    'swimming-pool',
    'coworking',
    'sauna',
    'fitness',
    'lobby'
];
export var ROOM_LAYOUT_NAMES = ['Studio', '1 bedroom', '2 bedroom'];
export var FAQ = [
    { title: 'Покупка онлайн', text: 'Возможно заключение сделки без непосредственного нахождения в стране. Осмотр объекта и консультация возможны с менеджером посредством видео или аудио звонка, а также через мессенджеры' },
    { title: 'Способы оплаты', text: 'Способы оплаты:\n- безналичная оплата\n- наличная оплата в батах\n- рассрочка без % на время строительства' },
    { title: 'Формы собственности', text: 'В комплексе Enigma Residence доступны следующие формы собственности:\n1. Foreign Freehold – полная собственность на иностранное физическое или юридическое лицо.\n2. Leasehold – долгосрочная аренда с пролонгацией каждые 30 лет.\n3. Thai Freehold – полная собственность для физических или юридических лиц Таиланда.' }
];
export var SOCIALS = [
    { name: 'instagram', link: 'https://www.instagram.com/enigma_residence_phuket?igsh=MWlmeHJmdnAxZzBuNg==' },
    { name: 'telegram', link: 'https://t.me/Robert_Enigma' },
    { name: 'whatsapp', link: 'https://wa.me/+66629052181' },
];
export var PRESENTATION_LINKS = {
    ru: 'https://drive.google.com/file/d/1Zp41WVeYDrcD4ziIpmzDBaDM8X2D8LI_/view?usp=sharing',
    en: 'https://drive.google.com/file/d/1ujSfbxUeZzsg-zVVJuMox63TUPGAmDCh/view?usp=sharing'
};
