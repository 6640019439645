import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import Map from '../../ui-kit/Map';
import styles from './contacts.module.css';
var Contacts = function (props, ref) {
    var t = useTranslation().t;
    var content = t('contacts__content', { returnObjects: true });
    return (_jsxs("div", { ref: ref, children: [_jsx("div", { className: styles.title, children: t('contacts__title') }), _jsx("div", { className: styles.content, children: content.map(function (_a, i) {
                    var title = _a.title, description = _a.description, href = _a.href;
                    return (_jsxs("div", { className: styles.content__item, children: [_jsx("div", { className: styles.content__item__title, children: title }), href ? (_jsx("a", { className: styles.content__item__description, href: href, target: "_blank", children: description })) : (_jsx("div", { className: styles.content__item__description, children: description }))] }, "contacts_".concat(i)));
                }) }), _jsx(Map, {})] }));
};
export default forwardRef(Contacts);
