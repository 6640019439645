import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef, useLayoutEffect } from 'react';
import Portal from '../Portal';
import { getImageLink } from '../../helpers';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './popup.module.css';
var Popup = function (props) {
    var closeHandler = props.closeHandler, title = props.title, sizes = props.sizes, images = props.images;
    var _a = useState(0), activeImage = _a[0], setActiveImage = _a[1];
    var t = useTranslation().t;
    var imagesRef = useRef([]);
    var imagesWrapper = useRef(null);
    var imagesInner = useRef(null);
    var rootRef = useRef(null);
    useLayoutEffect(function () {
        var zoom = document.documentElement.offsetWidth / 1920;
        if (zoom > 1) {
            rootRef.current.style.zoom = zoom;
        }
    }, []);
    var clickOutside = function (e) {
        var target = e.target;
        if (!target.closest('[data-tag]')) {
            closeHandler();
        }
    };
    var handleActiveImage = function (event) {
        var target = event.target;
        var image = target.closest('[data-tag]');
        if (image.dataset.tag !== 'image')
            return;
        var index = imagesRef.current.indexOf(image);
        index !== activeImage && setActiveImage(index);
    };
    var handleScroll = function (toBot) {
        imagesWrapper.current.scrollBy({
            top: (toBot ? 1 : -1) * 116,
            behavior: 'smooth',
        });
        setActiveImage(activeImage + (toBot ? 1 : -1));
    };
    var imagesFolder = title.replace(/ /g, '-');
    var room_names = t('room__layouts__content', { returnObjects: true });
    return (_jsx(Portal, { children: _jsx("div", { className: styles.popup, onClick: clickOutside, ref: rootRef, children: _jsx("div", { className: styles.content, children: _jsxs("div", { className: styles.container, "data-tag": "popup", children: [_jsx("button", { className: styles.close_btn, onClick: closeHandler }), _jsxs("div", { className: styles.popup_content, children: [_jsxs("div", { className: styles.images_list_block, children: [_jsx("button", { onClick: function () { return handleScroll(); }, className: styles.arrow_wrapper, disabled: activeImage === 0, children: _jsx("div", { className: cn(styles.arrow, styles.arrow_top) }) }), _jsx("div", { className: styles.images_list_wrapper, ref: imagesWrapper, children: _jsx("div", { className: styles.images_list, onMouseMove: handleActiveImage, ref: imagesInner, children: images.map(function (item, i) {
                                                    var _a;
                                                    return (_jsx("div", { ref: function (el) { return (imagesRef.current[i] = el); }, className: cn(styles.images_list__image, (_a = {}, _a[styles.images_list__image_active] = activeImage === i, _a)), "data-tag": "image", style: { backgroundImage: "url(".concat(getImageLink(item, '.jpg', imagesFolder), ")") } }, "popup_images_".concat(i)));
                                                }) }) }), _jsx("button", { onClick: function () { return handleScroll(true); }, className: styles.arrow_wrapper, disabled: activeImage === imagesRef.current.length - 1, children: _jsx("div", { className: styles.arrow }) })] }), _jsx("div", { className: styles.main_image_wrapper, children: _jsx("div", { className: styles.main_image, style: { backgroundImage: "url(".concat(getImageLink(images[activeImage], '.jpg', imagesFolder), ")") } }) }), _jsxs("div", { className: styles.right_content, children: [_jsx("div", { className: styles.title, children: title }), _jsx("div", { className: styles.sizes, children: sizes.map(function (_a, i) {
                                                var name = _a.name, size = _a.size;
                                                return (_jsxs("div", { className: styles.size_block, children: [_jsx("div", { children: room_names[name] }), _jsxs("div", { children: [size, "\u00A0\u043C\u00B2"] })] }, "popup_sizes_".concat(i)));
                                            }) })] })] })] }) }) }) }));
};
export default Popup;
