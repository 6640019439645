var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var validateEmail = function (email) {
    var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};
export var validatePhone = function (phone) {
    var _a;
    return ((_a = phone.split('_')[1]) === null || _a === void 0 ? void 0 : _a.length) > 8;
};
var validate = function (data, setErrors) {
    var errs = {};
    for (var elem in data) {
        if (elem === 'name') {
            if (!data[elem]) {
                errs.name = 'inputs__validate__name';
            }
        }
        if (elem === 'phone') {
            if (!data[elem]) {
                errs.phone = 'inputs__validate__tel';
            }
            else if (!validatePhone(data[elem])) {
                errs.phone = 'inputs__validate__tel_short';
            }
        }
        if (elem === 'email') {
            if (data[elem] && !validateEmail(data[elem])) {
                errs.email = 'inputs__validate__email';
            }
        }
    }
    setErrors(function (prev) { return (__assign(__assign({}, prev), errs)); });
    return errs;
};
export default validate;
