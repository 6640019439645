var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../utils/useIsMobile';
import Button from '../../ui-kit/Button';
import Block from '../../ui-kit/Block';
import Field from '../../ui-kit/Field';
import Form from '../../ui-kit/Form';
import { getImageLink } from '../../helpers';
import validation from '../../validation';
import styles from './inputs.module.css';
import { postData } from '../../api';
import Note from '../../ui-kit/Note';
var Inputs = function () {
    var _a = useState({ name: '', phone: '', email: '', comment: '' }), data = _a[0], setData = _a[1];
    var _b = useState({ name: '', phone: '', email: '', comment: '' }), errors = _b[0], setErrors = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState(false), showNote = _d[0], setShowNote = _d[1];
    var _e = useState(false), isSuccess = _e[0], setIsSuccess = _e[1];
    var t = useTranslation().t;
    var isMobile = useIsMobile();
    var submit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var vr, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    vr = validation(data, setErrors);
                    if (Object.keys(vr).length !== 0)
                        return [2 /*return*/];
                    setLoading(true);
                    return [4 /*yield*/, postData(data, setLoading)];
                case 1:
                    result = _a.sent();
                    setIsSuccess(result);
                    setShowNote(true);
                    setData({ name: '', phone: '', email: '', comment: '' });
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Block, { image: getImageLink("house-inputs".concat(isMobile ? '-mobile' : ''), '.png'), children: [showNote && _jsx(Note, { setShowNote: setShowNote, isSuccess: isSuccess }), _jsxs(Form, { onSubmit: submit, children: [_jsx("div", { className: styles.title, children: t('inputs__title') }), _jsx("div", { className: styles.text, children: t('inputs__text') }), _jsxs("div", { className: styles.inputs, children: [_jsx(Field, { name: "name", type: "email", autocomplete: "email", label: t('inputs__input__name'), value: data, setValue: setData, errors: errors, setErrors: setErrors }), _jsx(Field, { name: "phone", type: "tel", autocomplete: "tel", label: t('inputs__input__tel'), value: data, setValue: setData, errors: errors, setErrors: setErrors }), _jsx(Field, { name: "email", type: "email", autocomplete: "email", label: t('inputs__input__email'), value: data, setValue: setData, errors: errors, setErrors: setErrors }), _jsx(Field, { name: "comment", type: "email", label: t('inputs__input__comment'), value: data, setValue: setData, className: styles.textarea, errors: errors, setErrors: setErrors })] }), _jsxs("div", { className: styles.btns, children: [_jsx("div", { className: styles.btns__text, children: t('inputs__btns__text') }), _jsx(Button, { className: styles.btns__btn, disabled: loading, children: t('inputs__btns__btn') })] })] })] }));
};
export default Inputs;
