import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import styles from './button.module.css';
var Button = function (props) {
    var children = props.children, className = props.className, onClick = props.onClick, disabled = props.disabled;
    var btnCls = cn(styles.button, className);
    return (_jsx("button", { className: btnCls, onClick: onClick, disabled: disabled, children: children }));
};
export default Button;
