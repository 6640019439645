import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import styles from './accordion.module.css';
var Accordion = function (_a) {
    var _b;
    var title = _a.title, text = _a.text, tag = _a.tag, active = _a.active, removeActive = _a.removeActive;
    var _removeActive = function (event) {
        if (active) {
            event.stopPropagation();
            removeActive();
        }
    };
    return (_jsxs("div", { className: cn(styles.accordion, (_b = {}, _b[styles.accordion_active] = active, _b)), "data-tag": tag, children: [_jsxs("div", { className: styles.accordion__main_block, children: [_jsx("div", { className: styles.title, children: title }), _jsx("button", { className: styles.arrow, onClick: _removeActive })] }), _jsx("div", { className: styles.accordion__add_block, children: _jsx("div", { children: text }) })] }));
};
export default Accordion;
