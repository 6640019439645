import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useLayoutEffect } from 'react';
import gsap from 'gsap';
import { useIsMobile } from '../../utils/useIsMobile';
var FadeInWithScroll = function (props) {
    var ref = useRef(null);
    var isMobile = useIsMobile();
    useLayoutEffect(function () {
        var zoom = document.documentElement.offsetWidth / 1920;
        if (zoom > 1 && !props.noZoom) {
            ref.current.style.zoom = zoom.toString();
        }
        var el = ref.current;
        var observer = new IntersectionObserver(function (_a) {
            var entry = _a[0];
            if (entry.isIntersecting) {
                gsap.to(el, { opacity: 1, y: 0, duration: 1, ease: 'power3.out' });
                observer.unobserve(el);
            }
        }, { threshold: isMobile ? 0.15 : 0.5 });
        el && observer.observe(el);
        return function () { return el && observer.unobserve(el); };
    }, []);
    return (_jsx("div", { ref: ref, style: { opacity: 0, transform: "translateY(".concat(isMobile ? 80 : 40, "px)") }, children: props.children }));
};
export default FadeInWithScroll;
