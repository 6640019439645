import { useState, useEffect } from 'react';
var checkIsMobile = function () {
    return document.documentElement.offsetWidth <= 767;
};
export var useIsMobile = function () {
    var _a = useState(checkIsMobile), isMobile = _a[0], setIsMobile = _a[1];
    useEffect(function () {
        var handleResize = function () {
            setIsMobile(checkIsMobile);
        };
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return isMobile;
};
