import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from 'react';
var Form = function (_a) {
    var children = _a.children, onSubmit = _a.onSubmit;
    var handleSubmit = useCallback(function (e) {
        e.preventDefault();
        onSubmit && onSubmit();
    }, [onSubmit]);
    return (_jsx("form", { onSubmit: handleSubmit, method: "post", noValidate: true, children: children }));
};
export default Form;
