import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import App from './App';
import './i18n';
import './index.css';
var root = document.getElementById('root');
if (!root)
    throw new Error('root not found');
var container = createRoot(root);
var router = createBrowserRouter([
    {
        path: '/',
        element: _jsx(App, {})
    },
    {
        path: '*',
        element: _jsx(Navigate, { to: '/' })
    }
]);
container.render(_jsx(React.StrictMode, { children: _jsx(React.Suspense, { fallback: 'Loading...', children: _jsx(RouterProvider, { router: router }) }) }));
