var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Nav from '../../ui-kit/Nav';
import Social from '../../ui-kit/Social';
import Copyright from '../../ui-kit/Copyright';
import styles from './footer.module.css';
var Footer = function (props) {
    return (_jsxs("footer", { className: styles.footer, children: [_jsx(Copyright, {}), _jsx(Nav, __assign({}, props)), _jsx(Social, {})] }));
};
export default Footer;
