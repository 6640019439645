var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { ROOM_LAYOUTS, ROOM_LAYOUT_NAMES } from '../../consts';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './layouts.module.css';
import Popup from '../../ui-kit/Popup';
var Rooms = function (_, ref) {
    var t = useTranslation().t;
    var _a = useState(0), activeLayout = _a[0], setActiveLayout = _a[1];
    var _b = useState(false), splideInited = _b[0], setSplideInited = _b[1];
    var _c = useState(null), selectedLayout = _c[0], setSelectedLayout = _c[1];
    var _d = useState(false), showPopup = _d[0], setShowPopup = _d[1];
    var splideRef = useRef(null);
    useEffect(function () {
        var _a;
        var splideInstance = (_a = splideRef.current) === null || _a === void 0 ? void 0 : _a.splide;
        if (splideInstance && !splideInited) {
            splideInstance.on('move', function (newIndex) {
                setActiveLayout(ROOM_LAYOUTS[newIndex].layoutId);
            });
            setSplideInited(true);
        }
    }, []);
    var handleModal = function (i) {
        setSelectedLayout(i);
        setShowPopup(true);
    };
    var options = useMemo(function () {
        return {
            focusableNodes: 'button',
            arrows: false,
            pagination: false,
            direction: 'ltr',
            fixedWidth: Math.min(document.documentElement.offsetWidth * 0.21, 404),
            gap: 40,
            padding: { left: '29%', right: 96 },
            breakpoints: {
                1152: {
                    fixedWidth: document.documentElement.offsetWidth * 0.255,
                    gap: 12,
                    padding: { left: '30%', right: 78 },
                },
                767: {
                    fixedWidth: document.documentElement.offsetWidth * 0.52,
                    padding: 18,
                },
            },
        };
    }, [document.documentElement.offsetWidth]);
    var moveToElem = function (i) {
        var _a;
        var index = ROOM_LAYOUTS.findIndex(function (item) { return item.layoutId === i; });
        (_a = splideRef.current) === null || _a === void 0 ? void 0 : _a.go(index);
    };
    return (_jsxs("div", { className: styles.rooms, ref: ref, children: [showPopup && _jsx(Popup, __assign({ closeHandler: function () { return setShowPopup(false); } }, ROOM_LAYOUTS[selectedLayout])), _jsxs("div", { className: styles.content_wrapper, children: [_jsx("div", { className: styles.title, children: t('room__layouts__title') }), _jsx("div", { className: styles.layout_titles, children: ROOM_LAYOUT_NAMES.map(function (item, i) {
                            var _a;
                            return (_jsx("button", { className: cn(styles.layouts_title, (_a = {},
                                    _a[styles.layouts_title_active] = i === activeLayout,
                                    _a)), onClick: function () { return moveToElem(i); }, children: item }, "layout_title_".concat(i)));
                        }) })] }), _jsx(Splide, { options: options, hasTrack: false, ref: splideRef, children: _jsx(SplideTrack, { children: ROOM_LAYOUTS.map(function (_a, i) {
                        var _b;
                        var title = _a.title, total = _a.total, layoutId = _a.layoutId;
                        return (_jsx(SplideSlide, { children: _jsxs("button", { className: cn(styles.slide, (_b = {},
                                    _b[styles.slide_active] = layoutId === activeLayout,
                                    _b)), onClick: function () { return handleModal(i); }, children: [_jsxs("div", { className: styles.slide__title, children: [total, " \u043C\u00B2"] }), _jsx("div", { className: styles.slide__description, children: title })] }) }, "room_layout_".concat(i)));
                    }) }) })] }));
};
export default forwardRef(Rooms);
