import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLayoutEffect, useRef } from 'react';
import Header from './components/Header';
import Banner from './components/Banner';
import About from './components/About';
import Information from './components/Information';
import Layouts from './components/Layouts';
import Location from './components/Location';
import Conveniences from './components/Conveniences';
import Interior from './components/Interior';
import Questions from './components/Questions';
import Contacts from './components/Contacts';
import Footer from './components/Footer';
import FadeInWithScroll from './ui-kit/TransformWrapper';
import styles from './app.module.css';
import Inputs from './components/Inputs';
var App = function () {
    var ref = useRef(null);
    var aboutRef = useRef(null);
    var layoutsRef = useRef(null);
    var questionsRef = useRef(null);
    var contactsRef = useRef(null);
    useLayoutEffect(function () {
        window.scrollTo(0, 0);
    }, []);
    return (_jsxs("div", { className: styles.app, ref: ref, children: [_jsx(Header, { about: aboutRef, roomsLayout: layoutsRef, questions: questionsRef, contacts: contactsRef }), _jsx(FadeInWithScroll, { children: _jsx(Banner, {}) }), _jsx(FadeInWithScroll, { children: _jsx(About, { ref: aboutRef }) }), _jsx(FadeInWithScroll, { children: _jsx(Information, {}) }), _jsx(FadeInWithScroll, { children: _jsx(Location, {}) }), _jsx(FadeInWithScroll, { noZoom: true, children: _jsx(Conveniences, {}) }), _jsx(FadeInWithScroll, { children: _jsx(Interior, {}) }), _jsx(FadeInWithScroll, { noZoom: true, children: _jsx(Layouts, { ref: layoutsRef }) }), _jsx(FadeInWithScroll, { children: _jsx(Questions, { ref: questionsRef }) }), _jsx(FadeInWithScroll, { children: _jsx(Inputs, {}) }), _jsx(FadeInWithScroll, { children: _jsx(Contacts, { ref: contactsRef }) }), _jsx(FadeInWithScroll, { children: _jsx(Footer, { about: aboutRef, roomsLayout: layoutsRef, questions: questionsRef, contacts: contactsRef }) })] }));
};
export default App;
