import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '../../ui-kit/Accordion';
import styles from './questions.module.css';
var Questions = function (_, ref) {
    var accordionsList = useRef(null);
    var _a = useState(null), active = _a[0], setActive = _a[1];
    var t = useTranslation().t;
    var handleActive = function (event) {
        var target = event.target;
        var accordion = target.closest('[data-tag]');
        if (accordion) {
            setActive(Array.from(accordionsList.current.childNodes).indexOf(accordion));
        }
    };
    var content = t('faq__content', { returnObjects: true });
    return (_jsxs("div", { className: styles.questions, ref: ref, children: [_jsx("div", { className: styles.title, children: t('faq__title') }), _jsx("div", { className: styles.accordions_wrapper, onClick: handleActive, ref: accordionsList, children: content.map(function (_a, i) {
                    var title = _a.title, text = _a.text;
                    return (_jsx(Accordion, { title: title, text: text, tag: "accordion", active: i === active, removeActive: function () { return setActive(-1); } }, "accordion_".concat(i)));
                }) })] }));
};
export default forwardRef(Questions);
