import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import Block from '../../ui-kit/Block';
import Link from '../../ui-kit/Link';
import { getImageLink } from '../../helpers';
import styles from './location.module.css';
var Location = function () {
    var t = useTranslation().t;
    return (_jsx(Block, { image: getImageLink('house-location', '.png'), children: _jsxs("div", { className: styles.location, children: [_jsx("div", { className: styles.title, children: t('location__title') }), _jsx("div", { className: styles.text, children: t('location__text') }), _jsx("div", { className: styles.text, children: t('location__text2') }), _jsx(Link, { className: styles.link, href: "https://maps.app.goo.gl/4uAPBvubKnw8xjbP9", children: t('location__btn') })] }) }));
};
export default Location;
