import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import Block from '../../ui-kit/Block';
import { getImageLink } from '../../helpers';
import { useIsMobile } from '../../utils/useIsMobile';
import { useTranslation } from 'react-i18next';
import styles from './about.module.css';
var About = function (props, ref) {
    var t = useTranslation().t;
    var isMobile = useIsMobile();
    return (_jsx("div", { ref: ref, children: _jsx(Block, { image: getImageLink("house-about".concat(isMobile ? '-mobile' : ''), '.png'), children: _jsxs("div", { className: styles.blockText, children: [_jsx("div", { className: styles.title, children: t('about__title') }), _jsx("div", { className: styles.text, children: t('about__text') })] }) }) }));
};
export default forwardRef(About);
